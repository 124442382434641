import { useTranslation } from "react-i18next";

import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import { useCallback } from "react";
import {
  createTicketAutomationMessageRule,
  getTicketAutomationMessageRuleById,
  updateTicketAutomationMessageRuleById,
} from "../../../../../../../services/ticketAutomationMessageRules.service";
import { ITimeRuleProps } from "../../TimeRules/TimeRuleForm/models";
import { MessageFields } from "./MessageFields";
import { TMessageForm, messageSchema } from "./messageSchema";

const translationPath = "components.messages.";

const Message = ({
  id,
  ticketAutomationRuleId,
  onBackButton,
}: ITimeRuleProps) => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createTicketAutomationMessageRule
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateTicketAutomationMessageRuleById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getTicketAutomationMessageRuleById
  );

  const saveHandler = (v: TMessageForm) =>
    save({
      ...v,
      establishments: v.establishments
        ? v.establishments.map((e) => e.id)
        : undefined,
      tasyScheduleRecords: v.tasyScheduleRecords
        ? v.tasyScheduleRecords.map((sr) => sr.id)
        : undefined,
      tasyScheduleClassifications: v.tasyScheduleClassifications
        ? v.tasyScheduleClassifications.map((sc) => sc.id)
        : undefined,
      tasyScheduleTypes: v.tasyScheduleTypes
        ? v.tasyScheduleTypes.map((st) => st.id)
        : undefined,
      tasyHealthInsurances: v.tasyHealthInsurances
        ? v.tasyHealthInsurances.map((hi) => hi.id)
        : undefined,
      ticketAutomationRuleId: ticketAutomationRuleId ?? "",
      evaluationFormId: v.evaluationForm ? v.evaluationForm.id : undefined,
      initialDate: v.initialDate.toISOString().substring(0, 10),
      endDate: v.endDate.toISOString().substring(0, 10),
      mediaFilesIds: [],
      deleteMediaFilesIds: [],
    });

  const updateHandler = (v: TMessageForm, id: string) =>
    update({
      item: {
        id: id,
        ...v,
        establishments: v.establishments
          ? v.establishments.map((e) => e.id)
          : undefined,
        tasyScheduleRecords: v.tasyScheduleRecords
          ? v.tasyScheduleRecords.map((sr) => sr.id)
          : undefined,
        tasyScheduleClassifications: v.tasyScheduleClassifications
          ? v.tasyScheduleClassifications.map((sc) => sc.id)
          : undefined,
        tasyScheduleTypes: v.tasyScheduleTypes
          ? v.tasyScheduleTypes.map((st) => st.id)
          : undefined,
        tasyHealthInsurances: v.tasyHealthInsurances
          ? v.tasyHealthInsurances.map((hi) => hi.id)
          : undefined,
        ticketAutomationRuleId: ticketAutomationRuleId ?? "",
        evaluationFormId: v.evaluationForm ? v.evaluationForm.id : undefined,
        initialDate: v.initialDate.toISOString().substring(0, 10),
        endDate: v.endDate.toISOString().substring(0, 10),
        mediaFilesIds: [],
        deleteMediaFilesIds: [],
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TMessageForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TMessageForm = {
          ...data,
          initialDate: new Date(`${data.initialDate}T12:00:00Z`),
          endDate: new Date(`${data.endDate}T12:00:00Z`),
          tasyScheduleRecords: data.tasyScheduleRecords,
          evaluationForm: data.evaluationForm
            ? {
                id: data.evaluationForm.id,
                name: data.evaluationForm.title,
              }
            : null,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TMessageForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "message")}
      schema={messageSchema}
      subtitle={t(translationPath + "new_message")}
      subtitleWatchField={"name"}
      backButtonOnClick={onBackButton}
      redirectOnSave={false}
      onSuccess={onBackButton}
      isModalForm={true}
      propId={id}
      formActionsOnBottom={false}
      defaultValues={{ exception: false }}
      fields={() => <MessageFields />}
    />
  );
};

export default Message;
