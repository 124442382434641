import { RouteObject } from "react-router-dom";
import { DomainProvider } from "@4uhub/lib4uhub";

import WelcomePage from "../pages/welcomePage";
import GrantorsPage from "../pages/4uSalesContract/Register/Grantors/GrantorsPage";
import Grantor from "../pages/4uSalesContract/Register/Grantors/GrantorForm/Grantor";
import ContractClassificationsPage from "../pages/4uSalesContract/Register/ContractClassifications/ContractClassificationsPage";
import ContractClassification from "../pages/4uSalesContract/Register/ContractClassifications/ContractClassificationsForm/ContractClassification";

const salesContractRoutes: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage />,
  },
  {
    path: 'register/salesGrantors',
    element: (
      <DomainProvider 
        value={{ domainName: 'salesgrantor' }}
      >
        <GrantorsPage />
      </DomainProvider>
    )
  },
  {
    path: "register/salesGrantors/new",
    element: (
      <DomainProvider
        value={{ domainName: 'salesgrantor' }}
        actions={["Create"]}
      >
        <Grantor />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesGrantors/:id/edit",
    element: (
      <DomainProvider 
        value={{ domainName: 'salesgrantor' }}
        actions={["Update"]}
        >
        <Grantor />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractClassifications",
    element: (
      <DomainProvider value={{ domainName: 'salesgrantor' }}>
        <ContractClassificationsPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractClassifications/new",
    element: (
      <DomainProvider
        value={{ domainName: 'salesgrantor' }}
        actions={["Create"]}
      >
        <ContractClassification />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractClassifications/:id/edit",
    element: (
      <DomainProvider 
        value={{ domainName: 'salesgrantor' }}
        actions={["Update"]}
        >
        <ContractClassification />
      </DomainProvider>
    ),
  },
];

export default salesContractRoutes;
