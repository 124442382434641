import { z } from "zod";
import { buildTPath } from "../contract-classifications.utils";

const salesContractTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullable()
}, { invalid_type_error: buildTPath('errors.contract_type') });

export const contractClassificationchema = z.object(
  {
    name: z.string().min(1, buildTPath('errors.name')),
    enable: z.boolean(),
    salesContractType: salesContractTypeSchema,
  }
);

export type TContractClassificationForm = z.infer<typeof contractClassificationchema>;
